<template>
  <div>
    <h1>基本设置</h1>
    <div class="d-f">
      <div class="avatar-container">
        头像
        <div class="avatar">
          <img :src="avatarUrl" />
          <div class="repair-avatar" @click="updateAvatar">
            <p class="d-f a-c j-c">修改头像</p>
          </div>
        </div>
      </div>
      <a-form-model
        class="setting-form"
        ref="baseSettingForm"
        :model="form"
        :rules="rules"
        hideRequiredMark
      >
        <a-form-model-item label="用户名" prop="username">
          <a-input v-model="form.username" placeholder="请输入用户名"> </a-input>
        </a-form-model-item>
        <a-form-model-item label="姓名" prop="realName">
          <a-input v-model="form.realName" placeholder="请输入姓名"> </a-input>
        </a-form-model-item>
        <a-form-model-item label="手机号">
          <a-input placeholder="手机号" disabled v-model="form.mobile"> </a-input>
          <a class="changePhone" @click="visible = true">修改手机号</a>
        </a-form-model-item>
        <a-form-model-item label="学校ID">
          <a-input placeholder="学校ID" disabled v-model="form.schoolNum"> </a-input>
        </a-form-model-item>
        <a-form-model-item v-if="hasbound">
          微信号已绑定，<a @click="unbind">解除绑定</a>
        </a-form-model-item>
        <a-form-model-item v-else>
          微信号未绑定，<a @click="getUuid">请扫码绑定</a>
        </a-form-model-item>
        <div class="qrcode" ref="baseSettingQrcode"></div>
        <div class="dust d-f f-d a-c j-c" v-if="needUpdate">
          <h4>二维码已失效</h4>
          <a-button type="primary" @click="update"> 点击刷新 </a-button>
        </div>
        <div class="dust d-f f-d a-c j-c" v-if="hasScan">
          <h4>绑定中...</h4>
        </div>
        <a-form-model-item>
          <a-button type="primary" @click="submit">更新信息</a-button>
        </a-form-model-item>
      </a-form-model>
    </div>
    <!-- 修改手机框的弹窗 -->
    <a-modal
      v-model="visible"
      title="修改手机号"
      @ok="handleOk"
      @cancel="handleCancel"
      class="change-phone-model"
    >
      <!-- 新手机号 -->
      <a-form-model
        ref="changePhoneForm"
        :model="changePhoneForm"
        :rules="changePhoneFormRules"
        class="change-phone-form"
      >
        <a-form-model-item prop="newPhone">
          <a-input v-model="changePhoneForm.newPhone" placeholder="请输入新手机号">
          </a-input>
        </a-form-model-item>
        <a-row>
          <a-col :span="12">
            <a-form-model-item prop="code">
              <a-input v-model="changePhoneForm.code" placeholder="验证码"> </a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="2"></a-col>
          <a-col :span="10">
            <send-code
              ref="codeBaseSetting"
              :canSend="canSend"
              :totalCount="totalCount"
              @canSendAgain="getSendMessage"
              @click.native="getCode($event)"
            ></send-code>
          </a-col>
        </a-row>
      </a-form-model>
    </a-modal>
    <!-- 修改头像的弹窗 -->
    <a-modal v-model="avatarVisible" title="修改头像" @ok="handleAvatarOk">
      <!-- 图片剪裁以及预览 -->
      <a-row>
        <a-col :span="24" :style="{ height: '240px', width: '240px' }">
          <vue-cropper
            ref="cropper"
            :img="options.headImgUrl"
            :info="true"
            :autoCrop="options.autoCrop"
            :autoCropWidth="options.autoCropWidth"
            :autoCropHeight="options.autoCropHeight"
            :fixedBox="options.fixedBox"
            @realTime="realTime"
            outputType="png"
          />
          <div class="avatar-upload-preview" v-if="canShow">
            <img :src="previews.url" :style="previews.img" />
          </div>
          <div class="avatar-upload-preview" v-else>
            <img :src="avatarUrl" class="avatar" />
          </div>
        </a-col>
      </a-row>
      <br />
      <!-- 上传，上下左右旋转以及放大缩小 -->
      <a-row>
        <a-col :lg="2" :md="2">
          <a-upload
            action="#"
            :http-request="requestUpload"
            :showUploadList="false"
            :before-upload="beforeUpload"
            accept="image/*"
          >
            <a-button>选择<a-icon type="upload" /></a-button>
          </a-upload>
        </a-col>
        <a-col :lg="{ span: 1, offset: 7 }" :md="2">
          <a-button icon="plus" @click="changeScale(1)"></a-button>
        </a-col>
        <a-col :lg="{ span: 1, offset: 3 }" :md="2">
          <a-button icon="minus" @click="changeScale(-1)"></a-button>
        </a-col>
        <a-col :lg="{ span: 1, offset: 3 }" :md="2">
          <a-button icon="undo" @click="rotateLeft()"></a-button>
        </a-col>
        <a-col :lg="{ span: 1, offset: 3 }" :md="2">
          <a-button icon="redo" @click="rotateRight()"></a-button>
        </a-col>
      </a-row>
    </a-modal>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
import SendCode from "@/components/SendCode.vue";
let avatar = require("@/assets/images/enter_avatar.png");
import {
  userUpdate,
  getUserInfo,
  smsChangeMobileSend,
  userChangeMobile,
  userUnbindWx,
  getUuid,
  checkUuid,
} from "@/api/acl/user.js";
import { VueCropper } from "vue-cropper";
export default {
  name: "BaseSetting",
  components: { SendCode, VueCropper },
  computed: {
    //头像地址
    avatarUrl() {
      if (this.form.headImgUrl) {
        return this.form.headImgUrl;
      } else {
        return avatar;
      }
    },
  },
  data() {
    return {
      form: {},
      rules: {
        username: [
          {
            required: true,
            message: "请输入用户名",
            whitespace: true,
            trigger: ["change", "blur"],
          },
          {
            validator: (rule, value, callbackFn) => {
              const reg = /^[A-Za-z][0-9A-Za-z]{2,12}$/;
              if (!reg.test(value)) {
                callbackFn("用户名只能包括数字(不以数字开头)和字母且在3~12位之间");
                return;
              }
              callbackFn();
            },
            trigger: ["change", "blur"],
          },
        ],
        realName: [
          {
            required: true,
            message: "请输入姓名",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
      },
      //微信号是否绑定
      hasbound: true,
      //扫码参数uuid
      uuid: "",
      timer: null,
      //uuid过期需要更新
      needUpdate: false,
      //已经扫码正在绑定
      hasScan: false,
      changePhoneForm: {},
      changePhoneFormRules: {
        newPhone: [
          {
            required: true,
            message: "请输入手机号",
            whitespace: true,
            trigger: ["change", "blur"],
          },
          {
            pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
            message: "手机号格式错误",
            trigger: ["change", "blur"],
          },
        ],
        code: [
          {
            required: true,
            message: "请输入验证码",
            whitespace: true,
            trigger: ["change", "blur"],
          },
          {
            pattern: /^\d{4}$/,
            message: "验证码格式错误",
            trigger: ["change", "blur"],
          },
        ],
      },
      //修改手机号的显示隐藏
      visible: false,
      canSend: true,
      totalCount: 60,
      //修改头像的显示隐藏
      avatarVisible: false,
      canShow: false,
      //图片裁剪
      options: {
        headImgUrl: "", //裁剪图片的地址
        autoCrop: true, // 是否默认生成截图框
        autoCropWidth: 200, // 默认生成截图框宽度
        autoCropHeight: 200, // 默认生成截图框高度
        fixedBox: true, // 固定截图框大小 不允许改变
      },
      //图片预览
      previews: {},
      //图片文件
      file: null,
    };
  },
  methods: {
    //更新userInfo接口
    getUserInfo() {
      getUserInfo().then((res) => {
        this.$ls.set("userInfo", res.data, 7 * 24 * 60 * 60 * 1000); //有效7*24小时
        let userInfo = this.$ls.get("userInfo");
        if (userInfo.unionId != "") {
          this.hasbound = true;
        } else {
          this.hasbound = false;
        }
        this.form = userInfo;
        this.$emit("changeUserInfo", userInfo);
      });
    },
    //更新信息
    submit() {
      this.$refs.baseSettingForm.validate((valid) => {
        if (valid) {
          let params = {};
          params.id = this.form.id;
          params.realName = this.form.realName;
          params.username = this.form.username;
          let formdata = this.formdataify(params);
          userUpdate(formdata).then((res) => {
            this.$message.success("修改成功，请重新登录");
            this.$ls.set("token", null);
            this.$ls.set("userInfo", null);
            this.$store.commit("setList", []);
            this.$router.push("/login");
          });
        }
      });
    },
    //生成二维码
    bindQRCode() {
      this.$refs.baseSettingQrcode.innerHTML = "";
      new QRCode(this.$refs.baseSettingQrcode, {
        text: "https://cloud.smarteboard.cn/wx/bind?uuid=" + this.uuid,
        width: 140,
        height: 140,
        colorDark: "#333333", //二维码颜色
        colorLight: "#ffffff", //二维码背景色
        correctLevel: QRCode.CorrectLevel.L, //容错率，L/M/H
      });
      this.$refs.baseSettingQrcode.removeAttribute("title");
    },
    //绑定微信前获取uuid
    getUuid() {
      getUuid(this.form.id).then((res) => {
        this.uuid = res.data;
        console.log(this.uuid);
        this.bindQRCode();
        this.timer = setInterval(() => {
          this.checkUuid();
        }, 1000);
      });
    },
    //检查uuid状态
    checkUuid() {
      checkUuid({ uuid: this.uuid }).then((res) => {
        //扫码成功
        if (res.data.ret === 3) {
          this.hasScan = false;
          this.needUpdate = false;
          clearInterval(this.timer);
          this.timer = null;
          this.$refs.baseSettingQrcode.innerHTML = "";
          this.getUserInfo();
        }
        //失效
        else if (res.data.ret === 2) {
          clearInterval(this.timer);
          this.timer = null;
          this.needUpdate = true;
          this.hasScan = false;
        }
        //已经扫码
        else if (res.data.ret === 1) {
          this.hasScan = true;
          this.needUpdate = false;
        }
      });
    },
    //点击刷新
    update() {
      this.needUpdate = false;
      this.getUuid();
      this.timer = setInterval(() => {
        this.checkUuid();
      }, 1000);
    },
    //解除绑定
    unbind() {
      this.$confirm({
        title: "您确认解除绑定吗？",
        onOk: () => {
          userUnbindWx(this.form.id).then((res) => {
            this.$message.success("解绑成功");
            this.getUserInfo();
          });
        },
      });
    },
    //修改手机号确定
    handleOk() {
      this.$refs.changePhoneForm.validate((valid) => {
        if (valid) {
          let params = {};
          params.id = this.form.id;
          params.mobile = this.changePhoneForm.newPhone;
          params.code = this.changePhoneForm.code;
          userChangeMobile(params).then((res) => {
            this.$message.success("修改成功，请重新登录");
            this.$ls.set("token", null);
            this.$ls.set("userInfo", null);
            this.$store.commit("setList", []);
            this.$router.push("/login");
          });
        }
      });
    },
    //修改手机号取消
    handleCancel() {
      this.changePhoneForm = {};
      this.$refs.changePhoneForm.resetFields();
    },
    //获取验证码
    getCode(event) {
      event.stopPropagation();
      this.$refs["changePhoneForm"].validateField(["newPhone"], (Error) => {
        if (!Error) {
          if (this.canSend) {
            //发送短信验证码接口
            smsChangeMobileSend(this.changePhoneForm.newPhone).then((res) => {
              this.$message.success("验证码发送成功");
              this.canSend = false;
              this.$refs.codeBaseSetting.getCode();
            });
          }
        }
      });
    },
    //倒计时结束可以重新发送验证码（子组件传值）
    getSendMessage(data) {
      this.canSend = data;
    },
    //修改头像
    updateAvatar() {
      this.canShow = false;
      this.file = null;
      this.options.headImgUrl = "";
      this.avatarVisible = true;
    },
    //修改头像确定
    handleAvatarOk() {
      //没传图片
      if (this.file == null) {
        this.$message.error("请上传头像");
      }
      //上传了图片
      else {
        this.$refs.cropper.getCropBlob((data) => {
          //修改命名
          const time = new Date().getTime();
          const randomName = time + "_" + this.file.name;
          // blob转file
          let file = new window.File([data], randomName, {
            type: data.type,
          });
          let params = {};
          params.id = this.form.id;
          params.file = file;
          let formdata = this.formdataify(params);
          userUpdate(formdata).then((res) => {
            this.$message.success("修改成功");
            this.getUserInfo();
            this.avatarVisible = false;
          });
        });
      }
    },
    //json对象转换为formData格式
    formdataify(params) {
      const formData = new FormData();
      Object.keys(params).forEach((key) => {
        formData.append(key, params[key]);
      });
      return formData;
    },
    // 覆盖默认的上传行为
    requestUpload() {},
    // 向左旋转
    rotateLeft() {
      this.$refs.cropper.rotateLeft();
    },
    // 向右旋转
    rotateRight() {
      this.$refs.cropper.rotateRight();
    },
    // 图片缩放
    changeScale(num) {
      num = num || 1;
      this.$refs.cropper.changeScale(num);
    },
    // 上传预处理
    beforeUpload(file) {
      if (file.type.indexOf("image/") == -1) {
        this.$message.error("文件格式错误，请上传图片类型,如：JPG，PNG后缀的文件。");
      } else {
        this.canShow = true;
        this.file = file;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.options.headImgUrl = reader.result;
        };
      }
    },
    // 实时预览
    realTime(data) {
      this.previews = data;
    },
  },
  created() {
    let userInfo = this.$ls.get("userInfo");
    if (userInfo.unionId != "") {
      this.hasbound = true;
    } else {
      this.hasbound = false;
    }
    this.form = userInfo;
  },
  destroyed() {
    clearInterval(this.timer);
    this.timer = null;
  },
};
</script>

<style scoped lang="less">
h1 {
  font-size: 20px;
  font-family: "PingFang SC";
  font-weight: bold;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 16px;
}

.setting-form {
  width: 310px;
  position: relative;

  .ant-form-item {
    margin-bottom: 5px;
  }

  ::v-deep .ant-form-item-label {
    line-height: 32px;
  }

  .changePhone {
    position: absolute;
    top: -10px;
    right: -88px;
  }

  .qrcode {
    margin-bottom: 12px;
  }
  .dust {
    width: 140px;
    height: 140px;
    position: absolute;
    bottom: 57px;
    background-color: rgba(255, 255, 255, 0.93);
  }
}

.avatar-container {
  margin-top: 12px;
  width: 310px;

  .avatar {
    position: relative;

    img {
      width: 200px;
      height: 200px;
      border-radius: 50%;
    }

    .repair-avatar {
      position: absolute;
      top: 0;
      left: 0;
      width: 200px;
      height: 200px;
      border-radius: 50%;
      overflow: hidden;
      cursor: pointer;
      opacity: 0;

      p {
        margin-bottom: 0;
        width: 100%;
        height: 50px;
        background-color: rgba(0, 0, 0, 0.5);
        color: #ffffff;
        position: absolute;
        bottom: 0;
      }
    }

    .repair-avatar:hover {
      opacity: 1;
    }
  }
}

.change-phone-model {
  ::v-deep .ant-modal-body {
    display: flex;
    justify-content: center;
  }

  .change-phone-form {
    width: 330px;
  }
}

.avatar-upload-preview {
  position: absolute;
  top: 20px;
  left: 260px;
  width: 200px;
  height: 200px;
  box-shadow: 0 0 4px #ccc;
  overflow: hidden;
  border-radius: 50%;

  .avatar {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 200px;
    height: 200px;
  }
}
</style>
